import { QuoteBox } from "./QuoteBox"

export function QuoteBody(props) {
	return (
		<>
			<div className="row justify-center">
				{props.quotes.map(quote => <QuoteBox key={quote.trip_type} {...quote} selectQuote={function() {props.selectQuote(quote)} } selected={props.selectedQuote.trip_type == quote.trip_type}/>)}
			</div>
			<h4 className="text-center"><b>{props.passengers}</b> &nbsp; Passengers</h4>
			<table className="list-unstyled mt-3 mb-4 text-left">
				<tbody>
					<tr><td className="w-25 font-weight-bold">From:</td><td>{props.origin.area.name} - {props.origin.place.place_name}</td></tr>
					<tr><td className="w-25 font-weight-bold">To:</td><td>{props.destination.area.name} - {props.destination.place.place_name}</td></tr>
				</tbody>
			</table>
		</>
	)
}