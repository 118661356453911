import { PlaceAutocomplete } from './PlaceAutocomplete';
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { useEffect } from 'react';

export function getQuote(event, data) {
	return new Promise((resolve, reject) => {
		if (event) { event.preventDefault(); }
		const request = new XMLHttpRequest();
		request.onreadystatechange = function() {
			if (request.readyState !== 4) { return; }
			let data = JSON.parse(request.response);
			if (request.status >= 500) {
				dataLayer.push({ event: 'server_error', response: request.response })
			}
			if (request.status >= 300) {
				reject(request)
				if (!data._server_messages) { frappe.throw(data._error_message) }
				let messages = JSON.parse(data._server_messages);
				messages.map((data) => JSON.parse(data).message)
					.forEach(frappe.throw)
			}
			resolve(data)
		}

		request.open('POST', '/api/method/playa_transfers.utils.quote.shuttle')
		request.setRequestHeader("Content-Type", "application/json")
		request.setRequestHeader("X-Frappe-CSRF-Token", frappe.csrf_token)
		request.send(JSON.stringify(data))
	})
}

export function ModalQuoteForm(props) {
	const [origin, setOrigin] = useState(Object.assign({
		name: 'Cancun International Airport',
		place_id: 'ChIJvwmEZ0eATo8RkrvqeK_-1F8'
	}, props.origin));

	const [destination, setDestination] = useState(Object.assign({
		name: '',
		place_id: ''
	}, props.destination));

	const [passengers, setPassengers] = useState(props.passengers || 1);

	const apiKey = window.GOOGLE_API_KEY;
	const loader = new Loader({ apiKey, ...{ libraries: ['places'] }});

	const options = loader.load().then(
		() => ({
			bounds: new window.google.maps.LatLngBounds(
				new google.maps.LatLng(19.8, -89.7),
				new google.maps.LatLng(21.6, -86.7),
			),
			fields: ["place_id"]
		})
	);

	useEffect(() => {
		props.onDataChange({passengers, origin: origin.place_id, destination: destination.place_id})
	}, [origin, destination, passengers])

	return (
		<form>
			<div className="row align-items-end text-left">
				<div className="mt-3 col-md-12">
					<FormControl label="Origin:">
						<PlaceAutocomplete
							options={options}
							placechange={setOrigin}
							defaultValue={origin.name}
							name="origin" id="origin" />
					</FormControl>
				</div>
				<div className="mt-3 col-md-12">
					<FormControl label="Destination:">
						<PlaceAutocomplete
							options={options}
							placechange={setDestination}
							defaultValue={destination.name}
							type="text" id="destination" name="destination" />
					</FormControl>
				</div>
				<div className="mt-3 col-md-12">
					<FormControl label="Passengers:">
						<Input type="number" max="20" name="passengers" id="passengers" value={passengers} onChange={(e) => setPassengers(e.target.value)} />
					</FormControl>
				</div>
			</div>
		</form>
	)
}