import React, { useState, useRef, useEffect } from 'react';
import { omit } from 'lodash-es';
import { Input } from 'baseui/input';

const useInput = initialValue => {
	const [value, setValue] = useState(initialValue)
	return [
		{ value, onChange: (e) => setValue(e.target.value), onBlur: () => setValue(initialValue) },
		() => setValue('')
	];
}

function enableAutocomplete(options, inputEl, callback) {
		const el = document.querySelector(`input[name=${inputEl.current.props.name}]`);
		var autocomplete = new window.google.maps.places.Autocomplete(el, options)
		autocomplete.addListener('place_changed', () => {
			if (!callback) { return; }
			dataLayer.push({ event: `${inputEl.current.props.name}_selected`})
			callback({
				place_id: autocomplete.getPlace().place_id,
				name: el.value
			})
		})
}

export function PlaceAutocomplete(props) {
	const [inputProps] = useInput(props.defaultValue)
	const htmlProps = omit(props, ['options', 'defaultValue', 'placechange'])
	const inputEl = useRef(null)
	useEffect(() => {
		props.options.then((options) => enableAutocomplete(options, inputEl, props.placechange))
	}, [])
	return (
		<Input placeholder='Enter a location' {...Object.assign(htmlProps, inputProps)} ref={inputEl} clearable></Input>
	)
}