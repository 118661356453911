import { Shuttle } from '../components/Shuttle';
import { Client } from 'styletron-engine-atomic';
import { Provider } from 'styletron-react';
import { LightTheme, BaseProvider } from 'baseui';
import ReactDOM from 'react-dom';

const engine = new Client();

function HomePage() {
	return (
		<Provider value={engine}>
			<BaseProvider theme={LightTheme}>
				<Shuttle />
			</BaseProvider>
		</Provider>
	)
}


(function() {
	ReactDOM.render(<HomePage />, document.querySelector("[react-app]").parentElement)
})()