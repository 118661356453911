import React, { useRef, useState } from 'react';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal';
import { QuoteForm } from './QuoteForm';
import { QuoteBody } from './Quote/QuoteBody';
import { KIND } from 'baseui/button';

const BOOKING_URL = '/book';

const build_url = function(request_id, trip_type) {
	const url = new URL(BOOKING_URL, window.location.href);
	const referral_code = new URLSearchParams(window.location.search).get('referral_code');
	url.searchParams.set('request_id', request_id)
	url.searchParams.set('trip_type', trip_type)
	if (referral_code) {
		url.searchParams.set('referral_code', referral_code)
	}
	return url;
}

export function Shuttle() {
	const [response, setResponse] = useState({
		origin: { area: {}, place: {} },
		destination: { area: {}, place: {} },
		quotes: [],
		passengers: 0,
		request_id: null,
	});
	const [quote, setQoute] = useState({});
	const [isOpen, setIsOpen] = useState(false);

	const next = () => {
		const url = build_url(response.request_id, quote.trip_type);
		window.location.assign(url);
	}

	const handleQuoteResponse = (data) => {
		setResponse(data.message);
		setQoute(data.message.quotes[0]);
		setIsOpen(true);
	}

	return (
		<div className="card p-3">
			<QuoteForm handleQouteResponse={handleQuoteResponse} />
			<Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
				<ModalHeader>Destination Found</ModalHeader>
				<ModalBody>
					<QuoteBody {...response} selectQuote={setQoute} selectedQuote={quote}/>
				</ModalBody>
				<ModalFooter>
					<ModalButton kind={KIND.secondary} onClick={() => setIsOpen(false)}>Cancel</ModalButton>
					<ModalButton onClick={next}>Continue</ModalButton>
				</ModalFooter>
			</Modal>
		</div>
	)
}