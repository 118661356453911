import React, { useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { PlaceAutocomplete } from './PlaceAutocomplete';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Button } from 'baseui/button';
import { getQuote } from './ModalQuoteForm';


export function QuoteForm(props) {
	const [origin, setOrigin] = useState(Object.assign({
		name: 'Cancun International Airport',
		place_id: 'ChIJvwmEZ0eATo8RkrvqeK_-1F8'
	}, props.destination));

	const [destination, setDestination] = useState(Object.assign({
		name: '',
		place_id: ''
	}, props.destination));

	const [passengers, setPassengers] = useState(props.passengers || 1);
	const [loading, setLoading] = useState(false);

	const apiKey = window.GOOGLE_API_KEY;
	const loader = new Loader({ apiKey, ...{ libraries: ['places'] }});

	const options = loader.load().then(
		() => ({
			bounds: new window.google.maps.LatLngBounds(
				new google.maps.LatLng(19.8, -89.7),
				new google.maps.LatLng(21.6, -86.7),
			),
			fields: ["place_id"]
		})
	);

	const _getQuote = (event) => {
		dataLayer.push({ event: 'quote_requested'})
		setLoading(true)
		getQuote(event, { passengers, origin: origin.place_id, destination: destination.place_id,})
			.then(props.handleQouteResponse)
			.finally(() => setLoading(false))
	}

	return (
		<form onSubmit={_getQuote}>
			<div className="row align-items-end text-left">
				<div className="mt-3 col-sm-12 col-md">
					<FormControl label="Origin:">
						<PlaceAutocomplete
							options={options}
							placechange={setOrigin}
							defaultValue={origin.name}
							type="text" name="origin" id="origin" />
					</FormControl>
				</div>
				<div className="mt-3 col-sm-12 col-md">
					<FormControl label="Destination:">
						<PlaceAutocomplete
							options={options}
							placechange={setDestination}
							defaultValue={destination.name}
							type="text" id="destination" name="destination" />
					</FormControl>
				</div>
				<div className="mt-3 col-sm-12 col-md-2">
					<FormControl label="Passengers:">
						<Input type="number" max="20" name="passengers" id="passengers" value={passengers} onChange={(e) => setPassengers(e.target.value)} />
					</FormControl>
				</div>
			</div>
			<div className="text-center">
				<Button disabled={loading} isLoading={loading} className="mt-4" type="submit">
					Get Quote
				</Button>
			</div>
		</form>
	);

}